import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import "./directors.css";
import { useMediaQuery } from 'react-responsive';

import ImgBlobs04 from "../../images/ourwork/ourWork.webp";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  return isMobile ? children : null
}

export default function Directors() {

  const [directors, setDirectors] = useState([]);

  useEffect(() => {
    fetch('/directors.json')
      .then(response => response.json())
      .then(data => setDirectors(data))
      .catch(error => console.error('Error fetching vacancies:', error));
  }, []);

  return (
    <>
      <Desktop>
        <div className={`directorsContainer ${directors.length === 1 ? 'singleCard' : ''}`}>
          <img className='blobs_04' src={ImgBlobs04} alt="blob" />
          <div className='titleDirectors'>DIRECTORS</div>
          <div className='cardContainer'>
            {directors.map((directors, index) => (
              <div className="card" key={index} data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                <Link to={`${directors.url}`}>
                  <img className="cardImage" src={`/images/directors/${directors.image}`} alt="director" />
                </Link>
                <div className="cardTitle">{directors.title}</div>
                <div className="cardSubTitle">{directors.subtitle}</div>
              </div>
            ))}
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className='directorsContainer'>
          <div className='titleDirectors'>DIRECTORS</div>
          <div className='cardContainer'>
            {directors.map((directors, index) => (
              <div className="card" key={index} data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                <Link to={`${directors.url}`}>
                  <img className="cardImage" src={`/images/directors/${directors.image}`} alt="director" />
                </Link>
                <div className="cardTitle">{directors.title}</div>
                <div className="cardSubTitle">{directors.subtitle}</div>
                </div>
            ))}
          </div>
        </div>
      </Mobile>
    </>
  );
}